<template>
  <div>
    <b-row
      class="mb-2"
    >
      <b-col
        cols="auto"
      >
        <b-button
          variant="primary"
          @click="$router.go(-1)"
        >
          <feather-icon
            icon="ArrowLeftIcon"
            class="mr-50"
          />
          Voltar
        </b-button>
      </b-col>
    </b-row>
    <b-card>
      <b-row
        align-h="between"
      >
        <b-col
          cols="6"
          class="d-flex flex-column"
        >
          <div class="d-flex justify-content-start">
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  Campanha: {{ info.id }}
                </h4>
              </div>
            </div>
          </div>

          <b-row>
            <b-col>
              <div class="d-flex align-items-center ml-1 mt-2">
                <b-row>
                  <b-col
                    v-for="(item, i) in cards"
                    :key="item.icon"
                    class="cursor-pointer"
                    xl="4"
                    sm="3"
                    @click="itemClick(i)"
                  >
                    <b-media no-body>
                      <b-media-aside

                        class="mr-2 mt-2"
                      >
                        <b-avatar
                          size="23"
                          :variant="item.color"
                        >
                          <feather-icon
                            size="25"
                            :icon="item.icon"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-1">
                        <h4 class="font-weight-bolder mb-0">
                          {{ item.title }}
                        </h4>
                        <b-overlay
                          :show="item.loadCard"
                          class="ml-1"
                          spinner-small
                        />
                        <b-card-text class="font-small-3 mb-0">
                          {{ item.subtitle }}
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                </b-row></div>
              <b-row>
                <b-overlay
                  :show="loadExport"
                  variant="transparent"
                >
                  <b-col
                    class="text-left mt-2"
                    md="2"
                  >
                    <b-dropdown
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      split
                      right
                      size="sm"
                      text="Exportar"
                      variant="gradient-success"
                    >
                      <b-dropdown-item @click="exportListaManual">
                        Manual
                      </b-dropdown-item>
                      <b-dropdown-item @click="gerarLista('csv'), makeToast('success')">
                        Fastway
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                </b-overlay>
                <b-col
                  class="text-left mt-2"
                  md="3"
                >
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="gradient-danger"
                    size="sm"
                    @click="openModalDesativarCampanha(info.id)"
                  >
                    Desativar Campanha
                  </b-button>
                </b-col>
                <b-col
                  class="text-left mt-2"
                  md="3"
                >
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="gradient-warning"
                    size="sm"
                    @click="viewTemplate(info.id)"
                  >
                    Visualizar Template
                  </b-button>
                </b-col>
                <b-col
                  class="text-left mt-2"
                  md="3"
                >
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="gradient-primary"
                    size="sm"
                    @click="viewLogs(info.id)"
                  >
                    Visualizar Logs
                  </b-button>
                </b-col>
                <b-col
                  class="text-left mt-2"
                  md="3"
                >
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="gradient-warning"
                    size="sm"
                    @click="openModalInserirIdDiscador(info)"
                  >
                    Inserir discador ID
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="6"
          xl="6"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="AtSignIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Tipo</span>
              </th>
              <td class="pb-50">
                {{ info.tipo_campanha }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="ClockIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Respiro</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ info.respiro }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Data Inicio</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ formatTimezone(info.data_inicio_campanha) }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Data Final</span>
              </th>
              <td class="pb-50">
                {{ formatTimezone(info.data_final_campanha) }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Vencimentos Títulos</span>
              </th>
              <b-overlay
                :show="load"
                spinner-small
              >
                <td class="pb-50">
                  {{ menorDataTitulo }} à {{ maiorDataTitulo }}
                </td>
              </b-overlay>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Usuário</span>
              </th>
              <td class="pb-50">
                {{ info.usuario }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Filtro</span>
              </th>
              <td class="pb-50">
                <feather-icon
                  icon="EyeIcon"
                  class="mr-75 cursor-pointer"
                  @click="openModalFiltro(info.filtro)"
                />
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="itemClicked === 1"
      title="Acordos"
      class="mt-2"
    >
      <b-row
        align-h="center"
        class="mb-2"
      >
        <b-col
          md="8"
        >
          <b-form-input
            v-model="filter"
            placeholder="Pesquisar"
            type="search"
            size="sm"
          />
        </b-col>
      </b-row>
      <b-table
        hover
        striped
        responsive
        sticky-header="40vh"
        :filter="filter"
        :fields="[]"
        :items="itemsAcordos"
        @row-clicked="rowClick"
      />
    </b-card>
    <!-- CLIENTES -->
    <b-card
      v-if="itemClicked === 0"
      title="Clientes"
      class="mt-2"
    >
      <b-row
        class="mb-2 ml-1"
      >
        <b-button
          v-show="itemsClientes.length > 0"
          variant="outline-warning"
          @click="exportFile"
        >
          Exportar excel
        </b-button>
        <b-col
          md="8"
        >
          <b-form-input
            v-model="filter1"
            placeholder="Pesquisar"
            type="search"
            size="sm"
          />
        </b-col>
      </b-row>
      <b-table
        small
        striped
        responsive
        sticky-header="40vh"
        :filter="filter1"
        :fields="fieldsClientes"
        :per-page="25"
        :current-page="currentPage"
        :items="itemsClientes"
      >
        <template #cell(perfil_cliente)="data">
          <b-badge :variant="badgeVariantPerfilCliente(data.item.perfil_cliente)">
            {{ data.item.perfil_cliente }}
          </b-badge>
        </template>
        <template #cell(goTo)="data">
          <feather-icon
            icon="NavigationIcon"
            class="color-icon mr-1 cursor-pointer"
            @click="setCliente(data.item)"
          />
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="itemsClientes.length"
        :per-page="25"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-card>
    <b-modal
      ref="modalAcordo"
      title="Detalhes do acordo"
      hide-footer
      size="xl"
    >
      <ModalAcordo
        :acordo-id="acordoId"
      />
    </b-modal>
    <b-modal
      id="modal-disc"
      ref="modal-disc"
      title="Configurar Exportação"
    >
      <b-row>
        <b-col>
          <label for="">Selecione o Serviço</label>
          <v-select
            v-model="servicoSelected"
            :options="servicosFast"
          />
        </b-col>
      </b-row>

      <b-progress
        class="mt-1"
        :value="value"
        striped
        max="100"
      />

      <template #modal-footer="{}">
        <b-button
          size="md"
          variant="primary"
          :disabled="!servicoSelected"
          @click="exportFastway"
        >
          Exportar
        </b-button>
      </template>
    </b-modal>
    <b-card
      v-if="itemClicked === 3"
      title="Credores"
      class="mt-2"
    >
      <b-row
        align-h="center"
        class="mb-2"
      >
        <b-col
          md="8"
        >
          <b-form-input
            v-model="filter"
            placeholder="Pesquisar"
            type="search"
            size="sm"
          />
        </b-col>
      </b-row>
      <b-table
        hover
        striped
        responsive
        sticky-header="40vh"
        :filter="filter"
        :fields="fieldsCredores"
        :items="credores"
      />
    </b-card>
    <b-card
      v-if="itemClicked === 4"
      title="Operações"
      class="mt-2"
    >
      <b-row
        align-h="center"
        class="mb-2"
      >
        <b-col
          md="8"
        >
          <b-form-input
            v-model="filter"
            placeholder="Pesquisar"
            type="search"
            size="sm"
          />
        </b-col>
      </b-row>
      <b-table
        hover
        striped
        responsive
        sticky-header="40vh"
        :filter="filter"
        :fields="fieldsOperacoes"
        :per-page="25"
        :current-page="currentPage"
        :items="itemsOperacoes"
      />
      <b-pagination
        v-model="currentPage"
        :total-rows="itemsOperacoes.length"
        :per-page="25"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-card>
    <b-modal
      ref="openModalTemplate"
      title="Template da Campanha"
      size="lg"
      ok-only
      ok-title="Fechar"
    >
      <strong>{{ templateCampanha.titulo }} </strong>
      <div
        class="mt-1"
        v-html="templateCampanha.mensagem"
      />
    </b-modal>
    <b-modal
      ref="openModalLogs"
      title="Logs da Campanha"
      size="xl"
      ok-only
      ok-title="Fechar"
    >
      <b-table
        hover
        striped
        responsive
        sticky-header="50vh"
        :fields="logsCampanha"
        :per-page="25"
        :current-page="currentPage"
        :items="logsCampanha"
      >
        <template #cell(envio)="data">
          {{ data.item.envio ? dataHora(data.item.envio) : '' }}
        </template>
        <template #cell(telefone)="data">
          {{ data.item.telefone ? phoneLabel(data.item.telefone) : '' }}
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="logsCampanha.length"
        :per-page="25"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-modal>
    <b-modal
      ref="modalFiltro"
      title="Filtro da Campanha"
      ok-only
      ok-title="Fechar"
      size="xl"
    >
      <div
        v-for="(key, index) in Object.keys(filtroCampanha)"
        :key="key"
      >
        <b-card @click="toggleCollapse(index)">
          <b-row align-h="center">
            <strong>{{ key }}</strong>
          </b-row>
          <b-collapse v-model="collapseState[index]">
            <b-row align-h="center">
              <div
                v-for="(chave) in Object.keys(filtroCampanha[key])"
                :key="chave"
              >
                <strong class="ml-1">{{ chave }}: </strong> {{ filtroCampanha[key][chave] }}<br>
              </div>
            </b-row>
          </b-collapse>
        </b-card>
      </div>
    </b-modal>
    <b-modal
      ref="modalMotivoCancelamentoCampanha"
      title="Motivo Cancelamento"
      :ok-disabled="motivoCancelamentoCampanha.length === 0"
      @ok="desativarCampanha"
    >
      <v-select
        v-model="motivoCancelamentoCampanha"
        :options="itemsCancelamentoCampanha"
      />
    </b-modal>
    <b-modal
      ref="modalInsucessos"
      title="Insucessos"
      :ok-disabled="!insucessos.discador_id"
      cancel-title="Cancelar"
      cancel-variant="danger"
      @ok="insucessosStore"
    >
      <b-form>
        <label> Plure chamada ID</label>
        <b-form-input
          v-model="insucessos.discador_id"
          type="number"
        />
        <b-row align-h="center">
          <b-col>
            <label>Data Início Campanha</label>
            <b-form-input
              id="data-inicio"
              v-model="insucessos.data_inicio"
              locale="pt"
              type="date"
            />
          </b-col>
          <b-col class="mt-2">
            <b-form-input
              id="hora-inicio"
              v-model="insucessos.hora_inicio"
              type="time"
              placeholder="Inicio"
              local="en"
            />
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col>
            <label>Data Final Campanha</label>
            <b-form-input
              id="vencimento-final"
              v-model="insucessos.data_fim"
              locale="pt"
              type="date"
            />
          </b-col>
          <b-col class="mt-2">
            <b-form-input
              id="hora-final"
              v-model="insucessos.hora_final"
              type="time"
              placeholder="Final"
              local="en"
            />
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BAvatar,
  BTable,
  BFormInput,
  BButton,
  BModal,
  BDropdown,
  BDropdownItem,
  VBModal,
  BProgress,
  // BTooltip,
  BPagination,
  BMediaAside,
  BMediaBody,
  BCardText,
  BMedia,
  BOverlay,
  BCollapse,
  BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { saveAs } from 'file-saver'
import axios from '@/../axios-auth'
import ModalAcordo from './cards/ModalAcordo.vue'

const ExcelJS = require('exceljs')

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    BTable,
    BFormInput,
    BButton,
    BModal,
    BDropdown,
    BDropdownItem,
    ModalAcordo,
    vSelect,
    BProgress,
    // BTooltip,
    BPagination,
    BMediaAside,
    BMediaBody,
    BCardText,
    BMedia,
    BOverlay,
    BCollapse,
    BBadge,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  data() {
    return {
      lista: '',
      itemClicked: 0,
      filter: '',
      acordoId: '',
      info: {},
      colorCache: {},
      itemsAcordos: [],
      fieldsClientes: [
        { key: 'cliente_id', label: 'CÓDIGO', class: 'text-center' },
        { key: 'nome_cliente', label: 'CLIENTE', class: 'text-center' },
        { key: 'nome_credor', label: 'CREDOR', class: 'text-center' },
        { key: 'perfil_cliente', label: 'PERFIL', class: 'text-center' },
        { key: 'goTo', label: 'Ir para cliente', class: 'text-center' },
      ],
      servicosFast: [
        { id: 17, label: 'CARTEIRAS DIVERSAS' },
        { id: 22, label: 'DEMAIS CARTEIRAS' },
        { id: 34, label: 'ESTRATEGIA ATÉ 18:00 - I' },
        { id: 18, label: 'ESTRATEGIA ATÉ 18:00 - II' },
        { id: 19, label: 'FACULDADE' },
        { id: 43, label: 'GERENCIAMENTO DE PARCELAS' },
        { id: 24, label: 'NOTIFICAÇÃO DE DEBITO' },
        { id: 20, label: 'DISCADOR SEM RECEPTIVO - I' },
        { id: 11, label: 'DISCADOR SEM RECEPTIVO - II' },
      ],
      itemsClientes: [],
      servicoSelected: null,
      value: 0,
      currentPage: 1,
      credores: [],
      fieldsCredores: [
        { key: 'nome', label: 'NOME', class: 'text-center' },
        { key: 'count', label: 'QNTD CLIENTES', class: 'text-center' },
      ],
      cards: [],
      itemsOperacoes: [],
      titulosVencimentos: [],
      menorDataTitulo: '',
      maiorDataTitulo: '',
      load: true,
      fieldsOperacoes: [
        { key: 'cliente_id', label: 'CLIENTE ID', class: 'text-center' },
        { key: 'numero_operacao', label: 'NÚMERO DA OPERAÇÃO', class: 'text-center' },
        { key: 'data_vencimento', label: 'VENCIMENTO', class: 'text-center' },
        { key: 'valor_nominal', label: 'VALOR NOMINAL', class: 'text-center' },
      ],
      templateCampanha: {},
      logsCampanha: {},
      filtroCampanha: {},
      collapseState: {},
      itemsCancelamentoCampanha: [],
      campanhaID: null,
      motivoCancelamentoCampanha: [],
      loadExport: false,
      insucessos: {
        discador_id: null,
        data_inicio: '',
        data_fim: '',
        hora_inicio: '',
        hora_final: '',
        campanha_id: null,
      },
    }
  },
  created() {
    this.getInfoCampanha()
    this.getAcordos()
    this.getClientes()
    this.getOperacoes()
    this.setCards()
  },
  methods: {
    makeToast(variant = null) {
      this.$bvToast.toast('Mailing exportado com sucesso para Fastway!', {
        title: 'Operação realizada',
        variant,
        solid: true,
      })
    },
    async rowClick(item) {
      this.acordoId = item.id
      this.$refs.modalAcordo.show()
    },
    gerarLista(tipo) {
      const body = { extensao: tipo }
      axios.post(`api/v1/campanha/lista/${this.$route.params.id}`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }).then(res => {
        const li = res.data.dados

        li.map(linha => {
          this.linha += `${linha[0]}\n`
        })
        const hiddenElement = document.createElement('a')
        hiddenElement.href = `data:text/csv;charset=utf-8,${this.linha}`
        hiddenElement.target = '_blank'
        hiddenElement.download = `lista-campanha-${this.$route.params.id}.${tipo}`
        hiddenElement.click()
      })
    },
    getInfoCampanha() {
      axios.get(`api/v1/campanha/detalhar/${this.$route.params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.credores_clientes.map(item => {
          this.credores.push({
            nome: item.nome_credor,
            count: item.count,
          })
        })
        this.info = res.data.dados
        this.setCards()
      })
    },

    getClientes() {
      axios.get(`api/v1/campanha/clientes/${this.$route.params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }).then(res => {
        res.data.dados.map(item => {
          this.itemsClientes.push(item)
        })
      })
    },
    getOperacoes() {
      axios.get(`api/v1/campanha/operacoes/${this.$route.params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      }).then(res => {
        const dadosOperacoes = res.data.dados
        const Operacoes = []
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const prop in dadosOperacoes) {
          Operacoes.push({
            id: dadosOperacoes[prop].id,
            numero_operacao: dadosOperacoes[prop].numero_operacao,
            cliente_id: dadosOperacoes[prop].cliente_id,
            data_vencimento: this.dataHora(dadosOperacoes[prop].data_vencimento, true),
            valor_nominal: this.valorBr(dadosOperacoes[prop].valor_nominal, true),
          })
          this.titulosVencimentos.push(dadosOperacoes[prop].data_vencimento)
        }
        this.itemsOperacoes = Operacoes
        this.verificarDataTitulosVencimento()
        this.setCards()
        this.cards[4].loadCard = false
      })
    },

    getAcordos() {
      axios.get(`api/v1/campanha/acordos/${this.$route.params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }).then(res => {
        res.data.dados.map(dt => {
          this.itemsAcordos.unshift({
            id: dt.id,
            acordo: dt.numero_acordo,
            status: dt.status,
            qt_parcelas: dt.quantidade_parcelas,
          })
        })
      })
    },

    randomBadge(id) {
      const badges = ['primary', 'success', '', 'danger', 'warning', 'info', 'dark']
      return badges[Math.floor(Math.random() * id)]
    },

    exportFastway() {
      // const { servicoSelected } = this
      this.value = 20
      axios.post(`api/v1/campanha/lista/${this.$route.params.id}`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }).then(res => {
        this.value = 35
        const rows = [
          ['chave', 'nome', 'cpf', 'endereco', 'cep', 'municipio', 'uf', 'sexo'],
        ]
        for (let i = 1; i <= 15; i++) {
          rows[0].push(`fone${i}`)
        }
        res.data.map(item => {
          const {
            nome, id: chave, cpf_cnpj: cpf, endereco, cep, telefones,
          } = item.cliente
          const { nome: municipio, uf } = item.cliente.cidades
          const sexo = ''
          const arr = [chave, nome, cpf, endereco, cep, municipio, uf, sexo]
          for (let i = 0; i <= 14; i++) {
            telefones[i] ? arr.push(telefones[i].telefone) : arr.push('')
          }
          rows.push(arr)
        })
        const csvContent = `data:text/csv;charset=utf-8,${
          rows.map(e => e.join(';')).join('\n')}`

        this.value = 60

        const boleto = new File([csvContent], 'lista.csv')
        const data = new FormData()
        data.append('login', 'jairo')
        data.append('senha', '1234567')
        data.append('CampanhaId', 35)
        data.append('uploading', 42)
        data.append('arquivo', boleto)
        this.value = 70
        axios.post('https://vozcob.fastssl.com.br/api/mailingup/', data).then(result => {
          this.value = 100
          this.$swal({
            title: 'Operação Concluída!',
            html: `
              <p>Upload Efetuado</p>
              <br/>
              <strong>Campanha:</strong> ${result.data.campanhaNome} <br/>
              <strong>Mailing ID:</strong> ${result.data.mailingId} <br/>
              <strong>Registros:</strong> ${result.data.totalRegistros} <br/>
              <strong>Inconsistências:</strong> ${result.data.totalInconsistencias} <br/>
            `,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          setTimeout(() => {
            this.value = 0
          }, 2000)
        })
      }).catch(err => {
        this.$swal({
          title: 'Error!',
          html: `
            <p>Erro ao trazer dados da lista </p>
            <p>Tente novamente ou contate a equipe de desenvolvimento </p>
            <br/>
            Código do erro: <strong>${err.response ? err.response.status : 'Undefined'}</strong> <br/>
            Resposta: <strong>${err.response ? err.response.data : 'Undefined'}</strong> <br/>
          `,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },

    async setCliente(item) {
      this.$router.push({ name: 'negociacao-cliente', params: { id: item.cliente_id } })
    },

    async setCards() {
      const cards = [
        {
          color: 'light-primary',
          customClass: 'mt-1 mb-2 mb-xl-0',
          icon: 'UserIcon',
          subtitle: 'CLIENTES',
          title: this.info.total_clientes,
        },
        {
          color: 'light-warning',
          customClass: 'mt-1 mb-2 mb-xl-0',
          icon: 'TrendingUpIcon',
          subtitle: 'ACORDOS',
          title: this.itemsAcordos.length,
        },
        {
          color: 'light-success',
          customClass: 'mt-1 mb-2 mb-xl-0',
          icon: 'DollarSignIcon',
          subtitle: 'FATURAMENTO',
          title: this.info.faturamento,
        },
        {
          color: 'light-info',
          customClass: 'mt-1 mb-2 mb-xl-0',
          icon: 'BriefcaseIcon',
          subtitle: 'CREDORES',
          title: this.info.filtro.credores.length,
        },
        // {
        //   loadCard: true,
        //   color: 'light-secondary',
        //   customClass: 'mt-1 mb-2 mb-xl-0',
        //   icon: 'ActivityIcon',
        //   subtitle: 'OPERAÇÕES',
        //   title: this.itemsOperacoes.length,
        // },
        {
          color: 'light-info',
          customClass: 'mt-1 mb-2 mb-xl-0',
          icon: 'PhoneIcon',
          subtitle: 'TELEFONES',
          title: this.info.total_telefone,
        },
        {
          color: 'light-info',
          customClass: 'mt-1 mb-2 mb-xl-0',
          icon: 'MailIcon',
          subtitle: 'EMAILS',
          title: this.info.total_email,
        },
      ]
      this.cards = cards
    },

    async itemClick(data) {
      if (data === 0) {
        this.itemClicked = 0
      }
      if (data === 1) {
        this.itemClicked = 1
      }
      if (data === 2) {
        this.itemClicked = 2
      }
      if (data === 3) {
        this.itemClicked = 3
      }
      if (data === 4) {
        this.itemClicked = 4
      }
    },
    verificarDataTitulosVencimento() {
      this.load = false
      const dataMilisegundos = []
      this.titulosVencimentos.map(date => {
        dataMilisegundos.push(Date.parse(date))
      })

      const dataMaiorTituloMilisegundos = dataMilisegundos.reduce((a, b) => Math.max(a, b))
      const dataMenorTituloMilisegundos = dataMilisegundos.reduce((a, b) => Math.min(a, b))

      const dataMaiorTitulo = new Date(dataMaiorTituloMilisegundos).toLocaleString().substr(0, 10)
      const dataMenorTitulo = new Date(dataMenorTituloMilisegundos).toLocaleString().substr(0, 10)

      this.maiorDataTitulo = dataMaiorTitulo
      this.menorDataTitulo = dataMenorTitulo
    },

    async exportFile() {
      const file = await this.getDataToExported()
      if (file) {
        saveAs(file)
      }
    },

    async getDataToExported() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Relatório Clientes Campanha')
      const itemsPlan = this.itemsClientes

      worksheet.columns = [
        { header: 'CLIENTE ID', key: 'cliente_id' },
        { header: 'CREDOR', key: 'nome_credor' },
        { header: 'CLIENTE', key: 'nome_cliente' },
      ]

      itemsPlan.map(item => {
        worksheet.addRow([
          item.cliente_id,
          item.nome_credor,
          item.nome_cliente,
        ])
      })

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })

      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Relatório de clientes campanhas', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },
    desativarCampanha() {
      this.$swal({
        title: `Deseja desativar campanha ${this.campanhaID}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, desativar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        const body = {
          id: this.campanhaID,
          motivo_cancelamento_id: this.motivoCancelamentoCampanha.id,
        }
        if (result.value) {
          axios.post('api/v1/campanha/desativar', body, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          })
            .then(() => {
              this.$swal({
                title: 'Campanha desativada com sucesso!',
                icon: 'success',
                timer: 1500,
                showConfirmButton: false,
              })
            })
            .catch(error => {
              this.$swal({
                title: `Ops, algo deu errado: ${error.data.response.mensagem}`,
                icon: 'danger',
                timer: 1500,
                showConfirmButton: false,
              })
            })
        }
      })
    },
    viewTemplate(id) {
      axios.get(`api/v1/campanha/template/${id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          this.templateCampanha = {
            titulo: res.data.dados.titulo,
            mensagem: res.data.dados.mensagem,
          }
          this.$refs.openModalTemplate.show()
        })
        .catch(() => {
          this.$swal({
            title: 'ERRO',
            icon: 'error',
            text: 'Sem template para essa campanha!',
            customClass: {
              confirmButtonVariant: 'btn btn-primary',
            },
          })
        })
    },
    viewLogs(id) {
      axios.get(`api/v1/campanha/log/${id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          this.logsCampanha = res.data.dados
          this.$refs.openModalLogs.show()
        })
        .catch(() => {
          this.$swal({
            title: 'ERRO',
            icon: 'error',
            text: 'Algo errado ao trazer os logs dessa campanha!',
            customClass: {
              confirmButtonVariant: 'btn btn-primary',
            },
          })
        })
    },
    openModalFiltro(filtroCampanha) {
      this.filtroCampanha = filtroCampanha

      this.$refs.modalFiltro.show()
    },
    toggleCollapse(index) {
      // Verifica se o item está atualmente expandido
      if (this.collapseState[index]) {
        // Se estiver expandido, fecha o item
        this.collapseState[index] = false
      } else {
        // Se não estiver expandido, abre o item e atualiza a propriedade isCollapsed correspondente em filtroCampanha
        this.collapseState[index] = true
      }
    },
    async openModalDesativarCampanha(idCampanha) {
      this.campanhaID = idCampanha
      await axios.get('api/v1/motivo_cancelamento_campanha/', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(async res => {
        // eslint-disable-next-line array-callback-return
        await res.data.dados.map(motivoCancelamento => {
          this.itemsCancelamentoCampanha.unshift({
            id: motivoCancelamento.id,
            label: motivoCancelamento.nome,
          })
        })
      })
      this.$refs.modalMotivoCancelamentoCampanha.show()
    },
    async exportListaManual() {
      this.loadExport = true
      const body = {
        extensao: 'manual',
      }
      try {
        const response = await axios.post(`api/v1/campanha/lista/${this.$route.params.id}`, body, { responseType: 'blob' })
        const blob = new Blob([response.data], { type: 'application/octet-stream' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${this.$route.params.id}.csv`
        link.click()
        window.URL.revokeObjectURL(link.href)
        this.loadExport = false
        this.makeToast('success')
      } catch (error) {
        this.loadExport = false
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Parece que essa solicitação demorou demais, contate o setor de desenvolvimento',
        })
      }
    },
    openModalInserirIdDiscador(dados) {
      this.insucessos.data_inicio = dados.data_inicio_campanha.substring(0, 10)
      this.insucessos.data_fim = dados.data_final_campanha.substring(0, 10)

      this.insucessos.hora_inicio = dados.data_inicio_campanha.substring(11, 19)
      this.insucessos.hora_fim = dados.data_final_campanha.substring(11, 19)

      this.insucessos.campanha_id = dados.id

      this.$refs.modalInsucessos.show()
    },
    insucessosStore() {
      const horaFinal = this.insucessos.hora_final ? this.insucessos.hora_final : '23:59:59'

      const body = {
        discador_id: this.insucessos.discador_id,
        data_inicio_campanha: `${this.insucessos.data_inicio} ${this.insucessos.hora_inicio}`,
        data_final_campanha: `${this.insucessos.data_fim} ${horaFinal}`,
      }
      axios.put(`api/v1/campanha/update_discador_id/${this.insucessos.campanha_id}`, body)
        .then(() => {
          this.clearInsucessos()
          this.$swal({
            title: 'Ação executado com sucesso',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(error => {
          this.$swal({
            title: `Ops, algo deu errado: ${error.data.response.mensagem}`,
            icon: 'danger',
            timer: 1500,
            showConfirmButton: false,
          })
        })
    },
    clearInsucessos() {
      this.insucessos = {}
    },
  },
}
</script>

<template>
  <div>
    <b-tabs justified>
      <b-tab>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>Acordo</span>
        </template>
        <b-row class="mb-1">
          <b-col>
            <label><strong>Nº Acordo</strong></label>
            <b-form-input
              v-model="acordo.numero_acordo"
              disabled
            />
          </b-col>
          <b-col>
            <label><strong>Status</strong></label>
            <b-form-input
              v-model="acordo.status"
              disabled
            />
          </b-col>
          <b-col>
            <label><strong>Valor Nominal</strong></label>
            <b-form-input
              v-model="acordo.valor_nominal"
              disabled
            />
          </b-col>
          <b-col>
            <label><strong>Valor Entrada</strong></label>
            <b-form-input
              v-model="acordo.valor_entrada"
              disabled
            />
          </b-col>
          <b-col>
            <label><strong>Qntº Parcelas</strong></label>
            <b-form-input
              v-model="acordo.quantidade_parcelas"
              disabled
            />
          </b-col>
        </b-row>

        <b-row class="mb-1">
          <b-col>
            <label><strong>Valor da Parcela</strong></label>
            <b-form-input
              v-model="acordo.valor_parcela"
              disabled
            />
          </b-col>
          <b-col>
            <label><strong>{{ acordo.juros_porcento }}% Juros</strong></label>
            <b-form-input
              v-model="acordo.juros"
              disabled
            />
          </b-col>

          <b-col>
            <label><strong>{{ acordo.multas_porcento }}% Multas</strong></label>
            <b-form-input
              v-model="acordo.multas"
              disabled
            />
          </b-col>

          <b-col>
            <label><strong>{{ acordo.honorarios_porcento }}% Hon. Variável</strong></label>
            <b-form-input
              v-model="acordo.honorarios"
              disabled
            />
          </b-col>
        </b-row>

        <b-row
          v-if="!cancelado"
          class="mb-1"
        >
          <b-col>
            <label for=""><strong>Data Cancelamento</strong></label>
            <b-form-input
              v-model="acordo.data_cancelou"
              disabled
            />
          </b-col>
          <b-col>
            <label for=""><strong>Desc. Cancelamento</strong></label>
            <b-form-input
              v-model="acordo.descricao_cancelamento"
              disabled
            />
          </b-col>
          <b-col>
            <label for=""><strong>Usuário Cancelou</strong></label>
            <b-form-input
              v-model="acordo.usuario_cancelou_id"
              disabled
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ClockIcon" />
          <span>Operações</span>
        </template>
        <b-table
          sticky-header
          bordered
          striped
          :fields="fieldsOperacoes"
          :items="itemsOperacoes"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BTab,
  BTabs,
  BFormInput,
  BTable,
} from 'bootstrap-vue'
import axios from '@/../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    BFormInput,
    BTable,
  },
  props: {
    acordoId: {
      type: Number,
      required: true,
      default() {
        return 0
      },
    },
  },
  data() {
    return {
      acordo: {},
      cancelado: true,
      fieldsOperacoes: [
        {
          key: 'numero_operacao', label: 'operacao',
        },
        {
          key: 'aluno',
        },
        {
          key: 'data_vencimento', label: 'vencimento',
        },
        {
          key: 'valor_nominal', label: 'vl. nominal',
        },
      ],
      itemsOperacoes: [],
    }
  },
  created() {
    this.getAcordo()
  },
  methods: {
    getAcordo() {
      axios.get(`api/v1/acordo/edit/${this.acordoId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }).then(async res => {
        let acordo = []
        let operacao = []
        acordo = res.data.dados.acordo
        operacao = res.data.dados.operacoes

        // this.acordo = res.data.dados.acordo

        acordo.desconto = this.valorBr(parseFloat(acordo.desconto), true)
        acordo.honorarios = this.valorBr(parseFloat(acordo.honorarios), true)
        acordo.juros = this.valorBr(parseFloat(acordo.juros), true)
        acordo.multas = this.valorBr(parseFloat(acordo.multas), true)
        acordo.valor_entrada = this.valorBr(parseFloat(acordo.valor_entrada), true)
        acordo.valor_nominal = this.valorBr(parseFloat(acordo.valor_nominal), true)
        acordo.valor_parcela = this.valorBr(parseFloat(acordo.valor_parcela), true)

        acordo.data_cancelou ? acordo.data_cancelou = this.formatTimezone(acordo.data_cancelou) : ''
        acordo.usuario_cancelou_id ? acordo.usuario_cancelou_id : acordo.usuario_cancelou_id = ''
        this.acordo = acordo

        operacao.map(item => {
          item.data_vencimento = this.dataHora(item.data_vencimento, true)
          item.data_processamento = this.dataHora(item.data_processamento, true)
          item.data_atualizacao = this.dataHora(item.data_atualizacao, true)

          item.valor_nominal = this.valorBr(item.valor_nominal, true)
        })
        this.itemsOperacoes = operacao
        // this.itemsOperacoes = acordo/
      })
    },
  },
}
</script>
